import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DevelopersList from './DevelopersList';

function AnalyzeForm() {
  const [username, setUsername] = useState('');
  const [repositories, setRepositories] = useState(['']);
  const [token, setToken] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarError, setAvatarError] = useState('');
  const [formError, setFormError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState('form'); // 'form' или 'developers'

  const navigate = useNavigate();

  const constructProfileUrl = (username) => {
    return `https://github.com/${username}`;
  };

  useEffect(() => {
    const fetchAvatar = async () => {
      setAvatarError('');
      setAvatarUrl('');

      if (username.trim() === '') {
        return;
      }

      setIsFetching(true);
      try {
        const response = await axios.get(`https://api.github.com/users/${username}`);
        setAvatarUrl(response.data.avatar_url);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message) {
          setAvatarError(error.response.data.message);
        } else if (error.message) {
          setAvatarError(error.message);
        } else {
          setAvatarError('Произошла неизвестная ошибка.');
        }
      } finally {
        setIsFetching(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchAvatar();
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [username]);

  const addRepositoryField = () => {
    setRepositories([...repositories, '']);
  };

  const removeRepositoryField = (index) => {
    const newRepositories = [...repositories];
    newRepositories.splice(index, 1);
    setRepositories(newRepositories);
  };

  const updateRepository = (index, value) => {
    const newRepositories = [...repositories];
    newRepositories[index] = value;
    setRepositories(newRepositories);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError('');

    if (username.trim() === '') {
      setFormError('Пожалуйста, введите GitHub Username.');
      return;
    }

    const validRepositories = repositories.filter((repo) => repo.trim() !== '');
    if (validRepositories.length === 0) {
      setFormError('Пожалуйста, введите хотя бы один URL репозитория.');
      return;
    }

    setIsSubmitting(true);

    try {
      const payload = {
        name: username.trim(),
        repositories: validRepositories.map((repo) => repo.trim()),
        token: token.trim() !== '' ? token.trim() : null,
      };

      const response = await axios.post('/v1/get_developer_analyse', payload);

      if (response.data && response.data.codeReview) {
        navigate('/codeReview', { state: { codeReview: response.data.codeReview, detailedAnalysis: response.data } });
      }

      if (response.data.success) {
        const detailedAnalysis = response.data.data.detailedAnalysis;
        navigate('/competencies', { state: { detailedAnalysis } });
      } else {
        setFormError(response.data.message || 'Неизвестная ошибка.');
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        setFormError(err.response.data.message);
      } else if (err.message) {
        setFormError(err.message);
      } else {
        setFormError('Произошла неизвестная ошибка.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const LoadingScreen = () => (
    <div style={styles.loadingContainer}>
      <div style={styles.spinner}></div>
      <p style={styles.loadingText}>Анализируется...</p>
    </div>
  );

  return (
    <div style={styles.pageContainer}>
      {isSubmitting ? (
        <LoadingScreen />
      ) : (
        <div
          style={{
            ...styles.formContainer,
            width: activeTab === 'developers' ? '100%' : '600px',
          }}
        >
          <div style={styles.tabContainer}>
            <button
              onClick={() => setActiveTab('form')}
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === 'form' ? '#007bff' : '#f2f2f2',
                color: activeTab === 'form' ? '#fff' : '#333',
              }}
            >
              Форма анализа
            </button>
            <button
              onClick={() => setActiveTab('developers')}
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === 'developers' ? '#007bff' : '#f2f2f2',
                color: activeTab === 'developers' ? '#fff' : '#333',
              }}
            >
              Список разработчиков
            </button>
          </div>

          {activeTab === 'form' ? (
            <>
              <h1 style={styles.title}>Code Review и анализ компетенций разработчика</h1>
              <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>
                    GitHub Username<span style={styles.required}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    style={styles.input}
                    placeholder="Введите GitHub Username"
                  />
                </div>

                {avatarError && <p style={styles.error}>{avatarError}</p>}
                {username.trim() !== '' && !avatarError && (
                  <div style={styles.avatarContainer}>
                    {avatarUrl ? (
                      <img src={avatarUrl} alt="GitHub Avatar" style={styles.avatar} />
                    ) : (
                      <p style={styles.message}>Загрузка аватарки...</p>
                    )}
                  </div>
                )}

                <div style={styles.formGroup}>
                  <label style={styles.label}>
                    Репозитории GitHub<span style={styles.required}>*</span>:
                  </label>
                  {repositories.map((repo, index) => (
                    <div key={index} style={styles.repositoryGroup}>
                      <input
                        type="url"
                        value={repo}
                        onChange={(e) => updateRepository(index, e.target.value)}
                        required
                        style={styles.input}
                        placeholder="https://github.com/username/repo"
                      />
                      {repositories.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeRepositoryField(index)}
                          style={styles.removeButton}
                        >
                          Удалить
                        </button>
                      )}
                    </div>
                  ))}
                  <button type="button" onClick={addRepositoryField} style={styles.addButton}>
                    Добавить Репозиторий
                  </button>
                </div>

                <div style={styles.formGroup}>
                  <label style={styles.label}>Personal Access Token (необязательно):</label>
                  <div style={styles.tokenContainer}>
                    <input
                      type="password"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      style={styles.input}
                      placeholder="Ваш Personal Access Token"
                    />
                    <a
                      href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#creating-a-personal-access-token-classic"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.link}
                    >
                      Как создать токен?
                    </a>
                  </div>
                </div>

                {formError && <p style={styles.error}>{formError}</p>}

                <button type="submit" style={styles.button} disabled={isFetching}>
                  {isFetching ? 'Загрузка...' : 'Анализировать'}
                </button>
              </form>
            </>
          ) : (
            <DevelopersList />
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  pageContainer: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(/path/to/form-background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  formContainer: {
    width: '600px',
    padding: '25px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255)',
    boxShadow: '0 0 15px rgba(0,0,0,0.3)',
    position: 'relative',
    zIndex: 2,
    transition: 'width 0.3s ease',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    gap: '10px',
  },
  tabButton: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#333',
    fontWeight: 'bold',
  },
  required: {
    color: 'red',
    marginLeft: '4px',
  },
  repositoryGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  input: {
    flex: '1',
    padding: '10px',
    marginRight: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    alignSelf: 'flex-start',
    marginTop: '10px',
    fontSize: '14px',
  },
  removeButton: {
    padding: '10px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  button: {
    padding: '12px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    marginBottom: '15px',
  },
  message: {
    color: '#555',
    marginBottom: '10px',
    textAlign: 'center',
  },
  avatarContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  avatar: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '3px solid #007bff',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    zIndex: 2,
    position: 'relative',
  },
  spinner: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #007bff',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite',
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#fff',
  },
  tokenContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    marginLeft: '10px',
    color: '#007bff',
    textDecoration: 'none',
    fontSize: '14px',
  },
};

const styleSheet = document.styleSheets[0];
const keyframes = `@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default AnalyzeForm;
