// src/components/DevelopersList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DevelopersList() {
  const [developers, setDevelopers] = useState([]);
  const [expandedDevelopers, setExpandedDevelopers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Функция для агрегирования данных по developerName
  const aggregateDevelopers = (developersList) => {
    const aggregated = {};

    developersList.forEach((dev) => {
      const { developerName, totalFiles, languagesAnalyse } = dev;

      if (!aggregated[developerName]) {
        aggregated[developerName] = {
          developerName,
          totalFiles,
          languagesAnalyse: {},
        };
      }

      Object.entries(languagesAnalyse).forEach(([langName, langData]) => {
        if (!aggregated[developerName].languagesAnalyse[langName]) {
          aggregated[developerName].languagesAnalyse[langName] = langData;
        } else {
          // Если требуется агрегация данных для одного языка у одного разработчика, можно добавить соответствующую логику здесь
          // Например, объединение компетенций или усреднение оценок
          // В данном случае просто перезаписываем
          aggregated[developerName].languagesAnalyse[langName] = langData;
        }
      });
    });

    // Преобразуем объект в массив
    return Object.values(aggregated);
  };

  useEffect(() => {
    const fetchDevelopers = async () => {
      setLoading(true);
      setError('');

      try {
        const response = await axios.get('/v1/get_developers_list');
        const { data, success, message } = response.data;

        if (success && data && Array.isArray(data)) {
          const aggregatedDevelopers = aggregateDevelopers(data);
          setDevelopers(aggregatedDevelopers);
        } else {
          setError(message || 'Неверный формат данных от сервера.');
        }
      } catch (err) {
        console.error('Ошибка при получении списка разработчиков:', err);
        setError('Не удалось загрузить список разработчиков.');
      } finally {
        setLoading(false);
      }
    };

    fetchDevelopers();
  }, []);

  const toggleDeveloper = (developerName) => {
    setExpandedDevelopers((prevState) => ({
      ...prevState,
      [developerName]: !prevState[developerName],
    }));
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Список разработчиков</h1>

      {loading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p style={styles.loadingText}>Загрузка списка разработчиков...</p>
        </div>
      ) : error ? (
        <p style={styles.error}>{error}</p>
      ) : developers.length === 0 ? (
        <p style={styles.message}>Список разработчиков пуст.</p>
      ) : (
        <div style={styles.developersList}>
          {developers.map((dev, index) => (
            <div key={index} style={styles.developerCard}>
              <div style={styles.developerHeader}>
                <h2 style={styles.developerName}>{dev.developerName}</h2>
                <button
                  onClick={() => toggleDeveloper(dev.developerName)}
                  style={styles.toggleButton}
                >
                  {expandedDevelopers[dev.developerName] ? 'Свернуть' : 'Развернуть'}
                </button>
              </div>

              {expandedDevelopers[dev.developerName] && (
                <div style={styles.developerDetails}>
                  <p>
                    <strong>Количество проанализированных файлов:</strong> {dev.totalFiles}
                  </p>
                  {Object.entries(dev.languagesAnalyse).map(([langName, langData], idx) => (
                    <div key={idx} style={styles.languageSection}>
                      <h3 style={styles.languageName}>{langData.progLang}</h3>
                      <div style={styles.tableContainer}>
                        <table style={styles.table}>
                          <thead>
                            <tr>
                              <th style={styles.th}>Компетенция</th>
                              <th style={styles.th}>Оценка</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(langData.languageCompetencies).map(([competency, evaluation], idx) => (
                              <tr key={idx}>
                                <td style={styles.td}>{competency}</td>
                                <td style={styles.td}>{evaluation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div style={styles.skillsContainer}>
                        <p>
                          <strong>Algorithm Skills:</strong> {langData.algorithmSkills}
                        </p>
                        <p>
                          <strong>DB Skills:</strong> {langData.dbSkills}
                        </p>
                        <p>
                          <strong>Broker Skills:</strong> {langData.brokerSkills}
                        </p>
                        <p>
                          <strong>Summary:</strong> {langData.summary}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    width: '100%', // Устанавливаем ширину в 100%
    backgroundColor: 'rgba(255, 255, 255, 0.95)', // Полупрозрачный белый фон
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0,0,0,0.3)',
    boxSizing: 'border-box', // Учитываем padding в ширину
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    color: '#333',
    fontSize: '24px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  },
  spinner: {
    border: '8px solid #f3f3f3', // Светло-серый
    borderTop: '8px solid #007bff', // Синий
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite',
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#555',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: '50px',
  },
  developersList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%', // Устанавливаем ширину в 100%
  },
  developerCard: {
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    width: '100%', // Устанавливаем ширину в 100%
    boxSizing: 'border-box',
  },
  developerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  developerName: {
    margin: 0,
    color: '#007bff',
    fontSize: '20px',
    wordWrap: 'break-word', // Перенос длинных имен разработчиков
  },
  toggleButton: {
    padding: '6px 12px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    flexShrink: 0, // Предотвращает сжатие кнопки
  },
  developerDetails: {
    marginTop: '15px',
    width: '100%', // Устанавливаем ширину в 100%
    boxSizing: 'border-box',
    overflow: 'hidden', // Предотвращает выход контента за пределы контейнера
  },
  languageSection: {
    marginTop: '20px',
    overflow: 'hidden',
  },
  languageName: {
    marginBottom: '10px',
    color: '#28a745',
    fontSize: '18px',
    wordWrap: 'break-word', // Перенос длинных названий языков
  },
  tableContainer: {
    overflowX: 'auto', // Горизонтальная прокрутка
    marginBottom: '15px',
  },
  table: {
    width: '100%',
    minWidth: '400px', // Минимальная ширина для таблицы
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ddd',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    textAlign: 'left',
    wordWrap: 'break-word', // Перенос длинных заголовков
  },
  td: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'center',
    wordWrap: 'break-word', // Перенос длинных данных
  },
  skillsContainer: {
    marginTop: '10px',
    color: '#555',
    wordWrap: 'break-word', // Перенос длинных текстов
  },
  message: {
    textAlign: 'center',
    marginTop: '50px',
    fontSize: '18px',
    color: '#555',
  },
};

const styleSheet = document.styleSheets[0];
const keyframes =
  `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default DevelopersList;
