import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AnalyzeForm from './components/AnalyzeForm';
import CodeReview from './components/CodeReview';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<AnalyzeForm />} />
          <Route path="/competencies" element={<CodeReview />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
